import { Component } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { environment } from "../environments/environment";

declare let gtag;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  search: string;

  constructor(private _route: ActivatedRoute, private _router: Router) {
    if (environment.production && environment.envName === "prod") {
      this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          gtag("config", environment.googleAnalyticsID, {
            page_path: event.urlAfterRedirects,
            anonymize_ip: true
          });
        }
      });
    }
    this._route.queryParams.subscribe(params => {
      this.search = params["search"];
    });
  }
}
