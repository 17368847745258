import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SolAppConfigService, SolSSOLoginService, SolSSOUserService } from "common-ng";
import { environment } from "../../environments/environment";

const appInitializerFn = (appConfig: SolAppConfigService) => () =>
  appConfig.mergeConfig(environment).toPromise();

@NgModule({
  imports: [CommonModule],
  exports: [],
  declarations: [],
  providers: [
    SolSSOLoginService,
    SolSSOUserService,
    SolAppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFn,
      multi: true,
      deps: [SolAppConfigService]
    }
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error("CoreModule is already loaded. Import it in the root AppModule only");
    }
  }
}
