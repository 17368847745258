<div class="search-container">
  <button (click)="openOrSearch(); focusInput()" class="search-btn" mat-icon-button>
    <mat-icon class="search-icon">search</mat-icon>
  </button>
  <mat-form-field
    (mouseleave)="hideClearBtnOnMouseLeave()"
    (mouseover)="displayClearBtn()"
    [color]="dividerColor"
    floatLabel="never"
  >
    <input
      #inputElement
      (blur)="hideClearBtn()"
      (focus)="displayClearBtn()"
      (keyup.enter)="onKeyEnter($event)"
      [formControl]="searchControl"
      [name]="name"
      [placeholder]="placeholder"
      class="search-input"
      matInput
      sol-focus
      type="text"
    />
    <span
      (click)="clearText()"
      (mousedown)="preventBlur($event)"
      *ngIf="isOpen && isClearBtnVisible && !isInputEmpty() && !disableClearBtn"
      [@clearBtnAnimation]="isOpen"
      class="clear-btn"
      matSuffix
    >
      <mat-icon>clear</mat-icon>
    </span>
  </mat-form-field>
</div>
