import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT, LocationStrategy } from "@angular/common";

@Component({
  selector: "sol-404-error",
  templateUrl: "./404-error.component.html",
  styleUrls: ["./404-error.component.scss"]
})
export class Sol404ErrorComponent implements OnInit {
  constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _locationStrategy: LocationStrategy
  ) {}

  ngOnInit(): void {}

  home(): void {
    window.open(this._locationStrategy.getBaseHref(), "_self");
  }
}
