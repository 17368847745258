import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "./core/core.module";
import { HttpClientModule } from "@angular/common/http";
import { SolIframeService, SolMaterialModule, SolPipesModule } from "common-ng";
import { FlexLayoutModule } from "@angular/flex-layout";
import { LoadingBarHttpClientModule } from "@ngx-loading-bar/http-client";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    SolMaterialModule,
    FlexLayoutModule,
    SolPipesModule,
    LoadingBarHttpClientModule,
    AppRoutingModule
  ],
  providers: [SolIframeService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private _iframeService: SolIframeService) {}
}
