import { animate, keyframes, state, style, transition, trigger } from "@angular/animations";
import {
  Component,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: "[sol-focus]"
})
export class SolFocusDirective {
  hasFocus: boolean = false;

  constructor(private _elementRef: ElementRef) {}

  @HostListener("focus")
  onFocus(): void {
    this.hasFocus = true;
  }

  @HostListener("blur")
  onBlur(): void {
    this.hasFocus = false;
  }

  setFocus(): void {
    this._elementRef.nativeElement.focus();
  }
}

@Component({
  selector: "sol-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
  animations: [
    trigger("isOpenChanged", [
      state(
        "0",
        style({
          width: "0"
        })
      ),
      state(
        "1",
        style({
          width: "*"
        })
      ),
      transition("1 => 0", animate("300ms cubic-bezier(.4, 0, .2, 1)")),
      transition("0 => 1", animate("300ms cubic-bezier(.4, 0, .2, 1)"))
    ]),
    trigger("clearBtnAnimation", [
      state(
        "*",
        style({
          opacity: "1"
        })
      ),
      state(
        "void",
        style({
          opacity: "0"
        })
      ),
      transition(
        "void => *",
        animate(
          "150ms",
          keyframes([
            style({ opacity: 0, offset: 0 }),
            style({ opacity: 0, offset: 0.9 }),
            style({ opacity: 1, offset: 1 })
          ])
        )
      )
    ])
  ]
})
export class SolSearchComponent implements OnInit {
  @Input()
  url: string = null;

  @Input()
  name: string = "sol-search-input";

  @Input()
  placeholder: string = "Recherche";

  @Input()
  dividerColor: "primary" | "accent" | "warn" | "white" = "primary";

  @Input()
  isOpenOnInit: boolean = false;

  @Input()
  disableClearBtn: boolean = false;

  @Output()
  textChange: EventEmitter<string> = new EventEmitter<string>();
  @HostBinding("@isOpenChanged")
  @HostBinding("class.open")
  isOpen: boolean = false;
  @HostBinding("class.white")
  isWhite: boolean = false;
  searchControl: UntypedFormControl = new UntypedFormControl();
  isClearBtnVisible: boolean = false;
  /**
   * Reference on the SolFocusDirective used to programmatically set the focus on the input
   */
  @ViewChild(SolFocusDirective, { static: true })
  focusableInput: SolFocusDirective;

  constructor() {}

  private _text: string = null;

  @Input()
  get text(): string {
    return this._text;
  }

  set text(value: string) {
    this._text = value;
    this.textChange.emit(this._text);
  }

  /**
   * Reference to the Material Design design directive applied on the search input element
   */
  @ViewChild(MatInput, { static: true })
  _input: MatInput;

  get input(): MatInput {
    return this._input;
  }

  /**
   * Get access to the input ElementRef (useful to get access to the input nativeElement)
   */
  @ViewChild("inputElement", { static: true })
  _inputElement: ElementRef;

  get inputElement(): ElementRef {
    return this._inputElement;
  }

  ngOnInit(): void {
    this.isOpen = this.isOpenOnInit;
    this.isWhite = this.dividerColor === "white";

    this.searchControl.valueChanges
      .pipe(
        debounceTime(300), // wait 300ms after each keystroke before considering the term
        distinctUntilChanged()
      ) // escape modification if same as the previous one
      .subscribe(value => {
        this.text = value;
      });
  }

  search(): void {
    if (this.url && this._text) {
      const url = this.url.replace("{text}", this._text);
      window.open(url, "_blank");
    } else {
      this.textChange.emit(this._text);
    }
  }

  onKeyEnter(event: any): void {
    this.search();
  }

  openOrSearch(): void {
    if (this.isOpen) {
      this.search();
    } else {
      this.openInput();
    }
  }

  openInput(): void {
    this.isOpen = true;
  }

  clearText(): void {
    this.searchControl.setValue("");
  }

  focusInput(): void {
    this.focusableInput.setFocus();
  }

  displayClearBtn(): void {
    this.isClearBtnVisible = true;
  }

  hideClearBtn(): void {
    this.isClearBtnVisible = false;
  }

  hideClearBtnOnMouseLeave(): void {
    if (!this.focusableInput.hasFocus) {
      this.hideClearBtn();
    }
  }

  preventBlur(event: Event): void {
    event.preventDefault();
  }

  isInputEmpty(): boolean {
    if (this.inputElement.nativeElement.value) {
      return false;
    }
    return true;
  }
}
